<template>
    <base-section id="about-swift-pro">
        <base-section-heading
            color="grey lighten-2"
            icon="mdi-check"
            title="About Swift Pro Trade"
        />

        <v-container>
            <v-row>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="6">
                    <div>
                        <base-body class="text-justify">{{cards}}</base-body>
                    </div>
                </v-col>
                <v-col cols="12" md="3"></v-col>
            </v-row>
        </v-container>
    </base-section>
</template>

<script>
export default {
    name: 'AboutSwiftPro',

    data: () => ({
        cards:
            'Swiftpro capital is an international Forex trading investment company, established with the Vision of impacting it is local and international communities with opportunities to improving at least 70% of families/individuals at different levels bringing about poverty alleviation, sustainable financial growth, stability and freedom With Our team of integrity driven experts and professionals and a wide range coverage of our services globally, we are committed to provide our members with all encompassing investment services, plans and opportunities at different levels.',
    }),
};
</script>
